<template>
  <div class="template-email">
    <div
      v-if="loading && !getMailTemplateLoading"
      class="init-loading three-dots-loading"
    >
      Chargement en cours
    </div>
    <div v-else>
      <b-list-group>
        <div v-if="getAllTemplateEmail && getAllTemplateEmail.length > 0">
          <b-list-group-item
            href="#"
            :class="mailTemplate.visible ? '' : 'collapsed'"
            :aria-controls="'collapse-' + index"
            @click.prevent.stop="mailTemplate.visible = !mailTemplate.visible"
            v-for="(mailTemplate, index) in getAllTemplateEmail"
            :key="mailTemplate.id"
            :value="index.id"
          >
            <div class="content-email-template">
              <div class="title">{{ mailTemplate.title }}</div>
              <div class="right">
                <div class="btn-action">
                  <b-button
                    size="sm"
                    variant="secondary"
                    title="Visualiser"
                    @click.prevent.stop="handleShowClick(mailTemplate)"
                  >
                    <font-awesome-icon class icon="eye" />
                  </b-button>
                  <b-button
                    size="sm"
                    variant="primary"
                    title="Modifier"
                    @click.prevent.stop="handleUpdateClick(mailTemplate)"
                  >
                    <font-awesome-icon class icon="pencil-alt" />
                  </b-button>
                  <b-button
                    size="sm"
                    title="Supprimer"
                    variant="danger"
                    @click.prevent.stop="handleDeleteClick(mailTemplate)"
                  >
                    <font-awesome-icon class icon="trash-alt" />
                  </b-button>
                </div>
                <div class="icon">
                  <font-awesome-icon class="icon1" icon="angle-up" />
                  <font-awesome-icon class="icon1" icon="angle-down" />
                </div>
              </div>
            </div>
            <b-collapse
              :id="'collapse-' + index"
              v-model="mailTemplate.visible"
              class="mt-2"
            >
              <b-card>
                <b-row>
                  <b-col>
                    <div v-if="mailTemplate && mailTemplate.email">
                      <strong class="size">Adresse générique :</strong>
                      <span class="email">{{
                        mailTemplate && mailTemplate.email
                          ? mailTemplate.email
                          : ''
                      }}</span>
                    </div>
                    <div>
                      <strong class="size"
                        >Sous catégories des pièces jointes :</strong
                      >
                      <div
                        class="content-type"
                        v-if="
                          mailTemplate &&
                            mailTemplate.category_files &&
                            mailTemplate.category_files.length
                        "
                      >
                        <ul
                          v-for="item in mailTemplate.category_files"
                          :key="item.id"
                          :value="item.id"
                          class="size"
                        >
                          <li>
                            {{ item.name }}
                          </li>
                        </ul>
                      </div>
                      <div v-else class="message">
                        Aucun sous catégorie de pièce jointe
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-collapse>
          </b-list-group-item>
        </div>
        <b-list-group-item
          v-if="
            (!getAllTemplateEmail || !getAllTemplateEmail.length) && !loading
          "
          class="aucun-template-email"
          >Aucun templates e-mail</b-list-group-item
        >
      </b-list-group>
    </div>

    <b-modal
      no-close-on-backdrop
      ref="deleteMailTemplateModal"
      id="deleteMailTemplateModal"
      title=" Supprimer une Template email"
      :hide-footer="true"
      @hidden="resetModal"
    >
      <p>
        Êtes-vous certain de vouloir supprimer
        <strong>
          {{ TemplateMailToDelete ? TemplateMailToDelete.title : '' }}</strong
        >
        ?
      </p>
      <div class="message">
        <div v-if="getTemplateEmailLoading" class="loading">
          Chargement en cours...
        </div>
        <div v-if="getTemplateEmailError" class="error">
          <ul v-if="Array.isArray(getTemplateEmailError)">
            <li v-for="(e, index) in getTemplateEmailError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getTemplateEmailError }}</span>
        </div>
      </div>
      <div class="form-actions">
        <b-button
          variant="outline-secondary"
          @click.prevent.stop="hideModal('deleteMailTemplateModal')"
        >
          Annuler
        </b-button>
        <b-button variant="success" @click="supprimerTemplateMail">
          Valider
        </b-button>
      </div>
    </b-modal>
    <!-- Model Update Template Email -->
    <Modal
      v-model="showTemplateMailToUpdate"
      modalClass="ModelClass"
      title="Modifier une template E-mail"
    >
      <form
        v-if="TemplateMailToUpdate"
        ref="updateTemplateEmailForm"
        @submit.prevent="handleUpdateEmailTemplate"
      >
        <b-row class="row mb-1">
          <!-- Title -->
          <b-col col xs="12" sm="6" md="6">
            <b-form-group
              label="Titre * "
              label-for="title-template-email-input"
            >
              <b-form-input
                id="title-template-email-input"
                v-model="TemplateMailToUpdate.title"
                required
              ></b-form-input>
            </b-form-group>
            <!-- Objet  -->
            <b-form-group
              label="Objet * "
              label-for="title-template-email-input"
            >
              <b-form-textarea
                class="mt-2"
                rows="2"
                no-resize
                v-model="TemplateMailToUpdate.subject"
                required
              ></b-form-textarea>
            </b-form-group>
            <!-- SELECT CATEGRIE DES FICHIERS-->
            <b-form-group label="Catégories des pièces jointes">
              <multiselect
                class="select-categ-template"
                v-model="TemplateMailToUpdate.category_files"
                :options="subCategoryOfCategoryOptions || []"
                placeholder="Rechercher catégorie de pièce jointe"
                :multiple="true"
                :close-on-select="true"
                :clear-on-select="true"
                :preserve-search="true"
                :allow-empty="true"
                label="name"
                track-by="id"
                :preselect-first="false"
                group-values="subcategorie"
                group-label="categorie"
                :group-select="true"
                selectLabel=""
                deselectLabel=""
                selectedLabel=""
                selectGroupLabel=""
                deselectGroupLabel=""
              >
                <span slot="noResult"
                  >Aucune catégorie de pièce jointe trouvée.</span
                >
                <span slot="noOptions"
                  >Aucune catégorie de pièce jointe trouvée.</span
                >
              </multiselect>
            </b-form-group>
            <b-form-group label="Adresse générique *">
              <b-form-input
                type="email"
                v-model="TemplateMailToUpdate.email"
                required
              ></b-form-input>
            </b-form-group>
          </b-col>
          <!-- Fields -->
          <b-col col xs="12" sm="6" md="6">
            <div class="mb-1 titleFields">
              Champs
              <div class="CopieMsg" v-if="copierText">Text copier</div>
            </div>
            <div class="groupe-fields">
              <div
                v-for="(prop, val) of getEmailTemplatesFields"
                :key="prop.id"
                @click="CopyFields({ prop: prop, val: val })"
                class="filed-class"
                title="Copier"
              >
                <div class="icon-field">☰</div>
                <div class="value-field">{{ val }}</div>
                <div class="icon-copy"><font-awesome-icon icon="copy" /></div>
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="row">
          <!-- Body -->
          <b-col col xs="12" sm="12" md="12">
            <editor
              v-model="TemplateMailToUpdate.body"
              :init="computedDataTinymce.init"
            ></editor>
          </b-col>
        </b-row>
        <div class="message mt-2">
          <div v-if="getTemplateEmailLoading" class="three-dots-loading">
            Chargement en cours
          </div>
          <div v-if="getTemplateEmailError" class="error">
            <ul v-if="Array.isArray(getTemplateEmailError)">
              <li v-for="(e, index) in getTemplateEmailError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getTemplateEmailError }}</span>
          </div>
          <div v-if="error" class="error">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ error }}</span>
          </div>
        </div>
        <div class="form-actions mt-5">
          <b-button
            @click="hideModalTemplete('showTemplateMailToUpdate')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" type="submit">
            Valider
          </b-button>
        </div>
      </form>
    </Modal>
    <!--show template -->
    <b-modal
      no-close-on-backdrop
      ref="showMailTemplateModal"
      id="showMailTemplateModal"
      :title="
        emailTemplateToShow && emailTemplateToShow.title
          ? emailTemplateToShow.title
          : ''
      "
      :hide-footer="true"
      size="lg"
      @hidden="resetModal"
      scrollable
    >
      <div v-if="emailTemplateToShow" v-html="emailTemplateToShow.body"></div>
      <div class="message">
        <div v-if="getTemplateEmailLoading" class="loading">
          Chargement en cours...
        </div>
      </div>
      <div class="form-actions mt-5">
        <b-button
          variant="outline-secondary"
          @click="hideModal('showMailTemplateModal')"
        >
          Fermer
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script src="//cdn.tinymce.com/4/tinymce.min.js"></script>
<script>
import { mapActions, mapGetters } from 'vuex'
import VueModal from '@kouts/vue-modal'
import '@kouts/vue-modal/dist/vue-modal.css'

import Editor from '@tinymce/tinymce-vue'

export default {
  data() {
    return {
      TemplateMailToDelete: null,
      TemplateMailToUpdate: null,
      emailTemplateToShow: null,
      open: false,
      showTemplateMailToUpdate: false,
      copierText: false,
      loading: false,
      error: null
    }
  },
  computed: {
    ...mapGetters([
      'getAllTemplateEmail',
      'getMailTemplateLoading',
      'getTemplateEmailError',
      'getTemplateEmailLoading',
      'getEmailTemplatesFields',
      'getImageTemplateList',
      'getCategoriesPieceJointeFile'
    ]),
    subCategoryOfCategoryOptions: function() {
      if (
        this.getCategoriesPieceJointeFile &&
        this.getCategoriesPieceJointeFile.length
      ) {
        return this.getCategoriesPieceJointeFile.map(categorie => {
          return {
            categorie: categorie.name,
            subcategorie: categorie.subCategorie.map(subCategory => {
              return {
                name: subCategory.name,
                id: subCategory.id
              }
            })
          }
        })
      }
      return []
    },
    computedImageList() {
      return this.getImageTemplateList.map(item => {
        return { title: item.title, value: item.url }
      })
    },
    computedDataTinymce() {
      return {
        init: {
          selector: '#editor',
          init_instance_callback: function(editor) {
            var freeTiny = document.querySelector(
              '.tox-notifications-container'
            )
            if (freeTiny) {
              freeTiny.style.display = 'none'
            }
          },
          height: 450,
          fullpage_default_doctype: '<!DOCTYPE html>',
          fullpage_default_encoding: 'UTF-8',
          plugins: [
            'advlist autolink lists link image  print preview hr',
            'searchreplace visualblocks code ',
            'insertdatetime  table paste imagetools charmap'
          ],
          fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt 50pt',

          toolbar_mode: 'floating',
          powerpaste_allow_local_images: true,
          language: 'fr_FR',
          toolbar:
            ' undo redo | bold italic underline | alignleft aligncenter alignright alignjustify |  link image |' +
            ' bullist  numlist outdent indent  |  forecolor backcolor  |' +
            '   print preview  fullpage |' +
            ' hr charmap',
          menubar: 'favs file edit view insert format   ',
          menu: {
            format: {
              title: 'Format',
              items:
                'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat '
            }
          },
          contextmenu: false,
          removed_menuitems: 'paste pastetext',
          block_formats: 'box=alert-box; badge=badge; footer=footer',
          formats: {
            'alert-box': {
              block: 'div',
              styles: {
                'background-color': '#f4f5f9',
                padding: '20px'
              }
            },
            footer: {
              block: 'div',
              styles: {
                'background-color': ' #c7c7c7',
                padding: '20px'
              }
            },
            badge: {
              title: 'Badge',
              inline: 'span',
              styles: {
                display: 'inline-block',
                border: '.1rem solid transparent',
                'border-radius': '50px',
                padding: '1rem',
                'margin-top': '0px',
                'margin-bottom': '1rem',
                color: '#2276d2',
                'box-shadow': '2px 3px #d6d7da',
                'background-color': '#008cd6',
                color: 'white'
              }
            }
          },
          /* enable title field in the Image dialog*/
          image_title: true,
          /* enable automatic uploads of images represented by blob or data URIs*/
          automatic_uploads: true,
          image_list: success => success(this.computedImageList),
          file_picker_types: 'image',
          images_upload_handler: async (blobInfo, success, failure) => {
            let param = new FormData()
            param.append('image', blobInfo.blob())
            this.setLisImage(param)
              .then(response => {
                success(response.data.data.url)
              })
              .catch(error => {
                failure('HTTP Error: ' + error.message)
              })
          }
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'fetchAllEmailTemplates',
      'resetErrorEmailTemplate',
      'deleteTemplateEmail',
      'updateEmailTemplate',
      'setLisImage',
      'getAllImageTemplate',
      'fetchCategoriesPieceJointeFile'
    ]),
    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetModal()
    },
    async resetModal() {
      this.TemplateMailToDelete = null
      this.emailTemplateToShow = null
      this.TemplateMailToUpdate = {
        title: null,
        subject: null,
        body: null,
        specific_field: null,
        category_files: [],
        email: null
      }
      await this.resetErrorEmailTemplate()
      this.error = null
    },
    hideModalTemplete(template) {
      this[template] = false
      this.resetModal()
    },
    CopyFields(payload) {
      const el = document.createElement('textarea')
      el.value = payload.prop
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-9999px'
      document.body.appendChild(el)
      el.select()
      el.setSelectionRange(0, 99999) /*For mobile devices*/
      document.execCommand('copy')
      document.body.removeChild(el)
      this.copierText = true
      setTimeout(() => {
        this.copierText = false
      }, 800)
    },
    async handleUpdateClick(template) {
      this.TemplateMailToUpdate = template
      this.showTemplateMailToUpdate = true
      this.TemplateMailToUpdate.category_files = this.TemplateMailToUpdate.category_files.map(
        item => {
          return {
            id: item.id,
            name: item.name
          }
        }
      )
      // const response = await this.fetchAllEmailTemplates(
      //   this.TemplateMailToUpdate.id
      // )
      // if (response) {
      //   if (this.getAllTemplateEmail && this.getAllTemplateEmail.length) {
      //     for (
      //       let index = 0;
      //       index < this.getAllTemplateEmail.length;
      //       index++
      //     ) {
      //       const element = this.getAllTemplateEmail[index]
      //       if (element.id == template.id) {
      //         this.TemplateMailToUpdate.category_files =
      //           element.category_files && element.category_files.length
      //             ? element.category_files
      //             : []
      //       }
      //     }
      //   }
      // }
      this.getAllImageTemplate()
    },
    validateChamps(field) {
      const validate = {
        status: 'success',
        errors: [],
        show: true
      }
      if (!field.body) {
        validate.status = 'error'
        validate.errors.push('Vous devez remplir le contenu de la template')
      } else if (!field.specific_field || !field.specific_field.length) {
        validate.status = 'error'
        validate.errors.push(
          'Vous devez remplir le contenu de la template par les champs'
        )
      }
      return validate
    },
    async handleUpdateEmailTemplate() {
      this.error = null
      const specific_field = []
      if (this.TemplateMailToUpdate.body != null) {
        for (const [key, value] of Object.entries(
          this.getEmailTemplatesFields
        )) {
          if (this.TemplateMailToUpdate.body.indexOf(value) !== -1) {
            specific_field.push(value)
          }
        }
      }
      this.TemplateMailToUpdate.specific_field = specific_field
      const validate = this.validateChamps(this.TemplateMailToUpdate)
      if (validate.status === 'error') {
        this.error = validate.errors
        return
      }
      const response = await this.updateEmailTemplate({
        TemplateEmailToUpdate: this.TemplateMailToUpdate
      })
      if (response) {
        this.fetchAllEmailTemplates({
          id_template: this.TemplateMailToUpdate.id
        })
        this.hideModalTemplete('showTemplateMailToUpdate')
      }
    },
    handleDeleteClick(template) {
      this.TemplateMailToDelete = template
      this.$refs['deleteMailTemplateModal'].show()
    },
    async supprimerTemplateMail() {
      const response = await this.deleteTemplateEmail({
        template: this.TemplateMailToDelete
      })
      if (response) {
        this.hideModal('deleteMailTemplateModal')
      }
    },
    handleShowClick(emailTemplate) {
      // this.open = true
      this.emailTemplateToShow = emailTemplate
      this.$refs['showMailTemplateModal'].show()
    }
  },
  components: {
    Editor,
    Modal: VueModal
  },
  async mounted() {
    this.loading = true
    await this.fetchAllEmailTemplates({
      sort_by: 'full_name'
    })
    this.getAllImageTemplate()
    this.fetchCategoriesPieceJointeFile({ loading: true })
    this.loading = false
  }
}
</script>

<style scoped lang="scss">
.template-email {
  .list-group {
    .aucun-template-email {
      text-align: center;
      color: #007bff;
    }
    .list-group-item {
      .content-email-template {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-weight: bold;
          width: 300px;
        }
        .right {
          display: flex;
          justify-content: flex-end;

          .btn-action {
            display: block;
            margin-right: 20px;
            button {
              margin: 0 2px;
            }
          }
          .icon {
            display: flex;
            flex-direction: column-reverse;
            margin-right: 16px;
          }
        }
      }
      .size {
        font-size: 12px;
      }
      .email {
        font-size: 12px;
        font-weight: bold;
        color: #03a9f4;
        margin-left: 4px;
      }
      .content-type {
        ul {
          margin-top: 3px;
          margin-bottom: 2px;
        }
      }
      .message {
        color: #03a9f4;
        font-size: 12px;
        text-align: center;
      }
      &.collapsed {
        .icon {
          flex-direction: column !important;
        }
      }
    }
  }
  @media screen and (max-width: 580px) {
    .list-group {
      width: 111%;
      .list-group-item {
        .content-email-template {
          // display: block;
          .title {
            width: 100%;
          }
          .right {
            // display: block;
            .btn-action {
              display: flex;
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 400px) {
    .list-group {
      .list-group-item {
        .content-email-template {
          display: block;
          .title {
            width: 100%;
          }
          .right {
            // display: block;
            .btn-action {
              display: flex;
              margin-right: 20px;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.tox-notifications-container {
  display: none !important;
}
.template-email {
  .ModelClass {
    border-radius: 5px;
    max-width: 800px;
    width: auto;
    overflow: auto;
    height: 619px;
    .vm-title {
      font-size: 1.25rem;
    }
  }
}
.select-categ-template {
  .multiselect__option--group {
    text-align: center;
  }
}
.titleFields {
  display: flex;

  .CopieMsg {
    margin-left: 50px;
    color: #4caf50;
    font-weight: 12px;
  }
}
.groupe-fields::-webkit-scrollbar {
  height: 7px;
  width: 7px;
}
.groupe-fields::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #80808054;
  border-radius: 10px;
}
.groupe-fields::-webkit-scrollbar-thumb {
  background: #dcdee0;
  border-radius: 10px;
}
.groupe-fields::-webkit-scrollbar-thumb:hover {
  background: #888;
}
.groupe-fields {
  white-space: normal;
  max-height: 362px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 340px;
}
.filed-class {
  border: 1px solid #6c757d4d;
  background-color: #ebebeb5c;
  border-radius: 4px;
  position: relative;
  padding: 5px;
  width: 318px;
  margin: 4px;
  cursor: pointer;
  .icon-field {
    position: absolute;
    left: 6px;
    width: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .icon-copy {
    position: absolute;
    right: 6px;
    width: 25px;
    top: 30%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .value-field {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-left: 25px;
  }
}
</style>
